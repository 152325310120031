<template>
  <div class="pay">
    <div class="pay-header">
      <div class="pay-content">
        <div class="iconfont icon-shizhong pay-content__icon"></div>
        <div class="pay-content__text">
          <div class="pay-title">正在支付中…</div>
          <div class="pay-describe">交易完成后将自动返回</div>
        </div>
      </div>
    </div>
    <div class="pay-list">
      <ul class="pay-ul">
        <li class="pay-ul-item" v-for="item in form" :key="item.id">
          <span>{{item.name}}：</span>
          <span>{{item.value}}</span>
        </li>
        <div class="info"></div>
      </ul>
    </div>
    <button class="pay-btn" @click="payAgain">重新支付</button>
    <span class="pay-hint">如遇任何支付问题，请及时联系客服027-89990060</span>
  </div>
</template>
<script>
export default {
  name: "paymentPage",
  data() {
    return {
      form: [
        { id: "0", name: "商家名称", value: "武汉浩视奇科技有限公司" },
        { id: "1", name: "订单编号", value: 0 },
        { id: "2", name: "商品名称", value: "智慧桌面续费" },
        { id: "3", name: "下单时间", value: "" },
        { id: "4", name: "订单金额", value: "￥" + 0 }
      ]
    };
  },
  created() {},
  mounted() {
    this.__init();
  },
  methods: {
    __init() {
      this.form[3].value = GetQueryString("createTime"); //下单时间
      this.form[1].value = GetQueryString("orderFormId"); //订单编号
      this.form[4].value = GetQueryString("amount"); //订单总额
    },
    payAgain() {
      onBridgeReady();
    }
  }
};
function onBridgeReady() {
  WeixinJSBridge.invoke(
    "getBrandWCPayRequest",
    {
      appId: GetQueryString("appId"), //公众号ID，由商户传入
      timeStamp: GetQueryString("timeStamp"), //时间戳，自1970年以来的秒数
      nonceStr: GetQueryString("nonceStr"), //随机串
      package: GetQueryString("package"),
      signType: "RSA", //微信签名方式：
      paySign: GetQueryString("paySign") //微信签名
    },
    function(res) {
      console.log(res.err_msg);
      if (res.err_msg == "get_brand_wcpay_request:ok") {
        // 使用以上方式判断前端返回,微信团队郑重提示：
        //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
        location.href = "https://itv.housky.cn/?#/paymentSuccess";
      } else if(res.err_msg == "get_brand_wcpay_request:cancel"){
        alert("用户取消支付");
      }
    }
  );
}

if (typeof WeixinJSBridge == "undefined") {
  if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
  } else if (document.attachEvent) {
    document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
    document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
  }
} else {
  onBridgeReady();
}

function GetQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var QueryString =window.location.href.substring(window.location.href.indexOf('#/payment?')+10,window.location.href.length);
  var r = QueryString.substr(0).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
</script>

<style lang="scss" scope>
.pay {
  .pay-header {
    display: flex;
    height: 150px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #2452ee;
  }
  .pay-content {
    display: flex;
    justify-content: center;
    &__icon {
      margin-top: 5px;
      font-weight: 500;
      color: #ffffff;
    }
    &__text {
      margin: 0px 0px 0px 10px;
      font-size: 20px;
      color: #ffffff;
    }
  }
  .pay-describe {
    margin-top: 5px;
    font-size: 10px;
  }
  .pay-list {
    position: absolute;
    top: 100px;
    left: 20px;
    right: 17.5px;
    border-radius: 5px 5px 0 0;
    background-color: #ffffff;
    border: 1px solid #838383;
    border-bottom: 0px;
  }
  .pay-ul {
    list-style: none;
  }
  .pay-ul-item {
    height: 45px;
    line-height: 45px;
    margin: 0px 20px 0 20px;
    font-size: 14px;
    color: #838383;
    border-bottom: 1px solid #efe8e8;
  }
  .info {
    content: "";
    height: 0.5em;
    background: linear-gradient(
        135deg,
        transparent,
        transparent 45%,
        #838383,
        transparent 55%,
        transparent 100%
      ),
      linear-gradient(
        45deg,
        transparent,
        transparent 45%,
        #838383,
        transparent 55%,
        transparent 100%
      );
    background-size: 1em 1em;
    transform: rotateX(180deg);
    background-repeat: repeat-x, repeat-x;
  }
  .pay-btn {
    position: fixed;
    bottom: 50px;
    width: 100%;
    height: 40px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #2452ee;
  }
  .pay-hint {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 25px;
    font-size: 12px;
    color: #838383;
  }
}
</style>
